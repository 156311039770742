import React from 'react'

import { PagesIndexQuery } from '../../../../types/graphql-types'
import { master_category_build_checker } from '../../../utils'

import TaxonomyAllPostList from '../../molecules/TaxonomyBlogPostList/TaxonomyAllPostList'
import TaxonomyMasterCategoryList from '../../molecules/TaxonomyBlogPostList/TaxonomyMasterCategoryList'
import BlogPostCardListContainer from '../../molecules/BlogPostCardListContainer'

type many_psops_type = {
  master_category_edges: PagesIndexQuery['allContentfulMasterCategory']['edges']
}

export const ManyNewBlogPosts: React.FC<many_psops_type> = ({
  master_category_edges,
}) => {
  return (
    <>
      <BlogPostCardListContainer
        list_title="新着記事"
        button_name="Read More"
        link_path="/all_post"
      >
        <TaxonomyAllPostList pickup_number={4} />
      </BlogPostCardListContainer>
      {master_category_edges.map((edge) => {
        const { node } = edge
        return (
          master_category_build_checker(edge) && (
            <BlogPostCardListContainer
              // list_title={node.name}
              button_name="Read More"
              link_path={`/${node.slug}`}
              key={node.id}
            >
              <TaxonomyMasterCategoryList
                target_slug={node.slug}
                pickup_number={5}
                top_page
              />
            </BlogPostCardListContainer>
          )
        )
      })}
    </>
  )
}

export default ManyNewBlogPosts
