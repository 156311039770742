import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/react'

import { common_color } from '../../style/common_value'

const button_style = css({
  position: 'relative',
  display: 'inline-block',
  overflow: 'hidden',
  fontSize: '1.3rem',
  border: '1px solid',
  borderColor: common_color.text,
  borderRadius: '4px',
  transition: '.3s',
  '&:hover': {
    color: 'white',
    borderColor: common_color.gray,
    '&:before': {
      right: '-13%',
    },
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    zIndex: -1,
    top: '-5%',
    right: '-135%',
    width: '125%',
    height: '110%',
    backgroundColor: common_color.gray,
    transform: 'skewX(-35deg)',
    transition: '.3s',
  },
  a: {
    display: 'block',
    padding: '.8rem 1rem',
  },
})

type props_type = {
  link_path: string
  children: React.ReactNode
}

export const HoverEffectButton: React.FC<props_type> = ({
  link_path,
  children,
}) => {
  return (
    <div css={button_style}>
      <Link to={link_path}>{children}</Link>
    </div>
  )
}

export default HoverEffectButton
