import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/react'

import { PagesIndexQuery } from '../../types/graphql-types'
import { common_space, media_mobile } from '../style/common_value'
import { master_category_custom_srot } from '../utils'

import HeaderImage from '../components/atoms/HeaderImage'
import SvgAbarabakuhatsuMark from '../components/svg_components/AbarabakuhatsuMark'
import { IndexMetadata } from '../components/metadata/IndexMetadata'
import IndexNewBlogPostList from '../components/pages_parts/IndexBlogPostCards'

const overwrite_sytle = css({
  height: '60vh',
})

const index_body_style = css({
  margin: `0 ${common_space.s} 3rem`,
  [media_mobile.sp]: {
    margin: `0 0 3rem`,
  },
  // margin: '0 auto 3rem',
})

const abara_mark_style = css({
  width: '100%',
  maxWidth: '450px',
  padding: '0 1rem',
})

const title_style = css({
  textIndent: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  height: '0',
})

type props_type = {
  data: PagesIndexQuery
}

const Component: React.FC<props_type> = ({ data }) => {
  const site_metadata = data.contentfulSiteMetaData
  const { site_header_image } = site_metadata

  const master_category_data = data.allContentfulMasterCategory.edges
  master_category_data.sort(master_category_custom_srot)

  const all_blog_post_number = data.allContentfulBlogPost.edges.length

  return (
    <>
      <IndexMetadata />
      <h1 css={title_style}>{site_metadata.site_title}</h1>
      <HeaderImage
        image_set={site_header_image}
        css_overwrite={overwrite_sytle}
      >
        <div css={abara_mark_style}>
          <SvgAbarabakuhatsuMark />
        </div>
      </HeaderImage>
      <div css={index_body_style}>
        <IndexNewBlogPostList
          master_category_edges={master_category_data}
          all_blog_post_number={all_blog_post_number}
        />
      </div>
    </>
  )
}

export default Component

export const query = graphql`
  query PagesIndex {
    contentfulSiteMetaData {
      site_title
      site_header_image {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        title
      }
    }
    allContentfulMasterCategory {
      edges {
        node {
          name
          slug
          id
          sort_order_priority
          category {
            blog_post {
              id
            }
          }
        }
      }
    }
    allContentfulBlogPost {
      edges {
        node {
          id
        }
      }
    }
  }
`
