import React from 'react'
import { css } from '@emotion/react'

import BlogPostCardListContainer from '../../molecules/BlogPostCardListContainer'
import TaxonomyAllPostList from '../../molecules/TaxonomyBlogPostList/TaxonomyAllPostList'

const blog_posts_style = css({
  marginBottom: '-1.5rem',
})

export const FewNewBlogPosts: React.FC = () => {
  return (
    <div css={blog_posts_style}>
      <BlogPostCardListContainer
        list_title="記事一覧"
        // button_name="Read More"
        link_path="/all_post"
      >
        <TaxonomyAllPostList pickup_number={9} />
      </BlogPostCardListContainer>
    </div>
  )
}

export default FewNewBlogPosts
