import React from 'react'
import { css } from '@emotion/react'
import HoverEffectButton from '../atoms/HoverEffectButton'

import {
  common_color,
  media_mobile,
  blog_post_card_mobile,
  post_card_grid_template_columns,
} from '../../style/common_value'

const blog_post_card_style = css({
  padding: '2rem 0',
  [media_mobile.tab]: {
    padding: '3rem 0',
  },
})

const blog_post_card_list_title_style = css({
  fontSize: '1.5rem',
  position: 'relative',
  display: 'block',
  margin: '0 auto 3rem',
  textAlign: 'center',
  lineHeight: '2.5rem',
  [media_mobile.tab]: {
    fontSize: '2rem',
    lineHeight: '3rem',
    marginBottom: '4rem',
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    bottom: '-5px',
    display: 'inline-block',
    width: '40px',
    height: '4px',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: common_color.gray,
    borderRadius: '2px',
    [media_mobile.tab]: {
      bottom: '-15px',
      width: '60px',
      height: '5px',
    },
  },
})

const post_card_list_style = css({
  // 記事3つを中央寄せで並べるための幅
  maxWidth: '1112px',
  margin: '0 auto',

  fontSize: '1rem',
  '.post_card_list': {
    display: 'grid',
    gap: '2rem 1rem',
    gridTemplateColumns: post_card_grid_template_columns,
    justifyContent: 'center',
    marginBottom: '2rem',
    [media_mobile.tab]: {
      gap: '4rem 1rem',
      marginBottom: '4rem',
    },
  },
  '.taxonomy_title': {
    display: 'none',
  },
  // トップページでタブレット時にブログカードを4枚並べるための設定
  '.hide_in_tablet_size': {
    display: 'inline-block',
  },
  'div:nth-of-type(n+4)': {
    display: 'none',
  },
  [blog_post_card_mobile.tab]: {
    '.hide_in_tablet_size': {
      display: 'none',
      '& ~ li:nth-of-type(5)': {
        display: 'inline-block',
      },
    },
    'div:nth-of-type(n+4)': {
      display: 'inline-block',
    },
  },
  [blog_post_card_mobile.desktop]: {
    '.hide_in_tablet_size': {
      display: 'inline-block',
      '& ~ li:nth-of-type(5)': {
        display: 'none',
      },
    },
    'div:nth-of-type(n+4)': {
      display: 'none',
    },
  },
})

const post_card_list_style_add_taxonomy_title = css(post_card_list_style, {
  '.taxonomy_title': [blog_post_card_list_title_style],
})

const button_container_style = css({
  display: 'flex',
  justifyContent: 'center',
})

type blog_post_card_type = {
  list_title?: string
  button_name?: string
  link_path: string
  children: React.ReactNode
}

export const BlogPostCardListContainer: React.FC<blog_post_card_type> = ({
  list_title,
  button_name,
  link_path,
  children,
}) => {
  return (
    <div css={blog_post_card_style}>
      {list_title && (
        <div css={blog_post_card_list_title_style}>{list_title}</div>
      )}
      <div
        css={
          list_title
            ? post_card_list_style
            : post_card_list_style_add_taxonomy_title
        }
      >
        {children}
      </div>
      {button_name && (
        <div css={button_container_style}>
          <HoverEffectButton link_path={link_path}>
            {button_name}
          </HoverEffectButton>
        </div>
      )}
    </div>
  )
}

export default BlogPostCardListContainer
