import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { IndexMetadataQuery } from '../../../types/graphql-types'

import { PostMetadata, JsonLdMetadata } from './MetadataCore'
import { compare_dates_return_newer } from '../../utils'

export const IndexMetadata: React.FC = () => {
  const query: IndexMetadataQuery = useStaticQuery(graphql`
    query IndexMetadata {
      contentfulSiteMetaData {
        site_title
        site_url
        site_description
        author_data {
          author_name
          url
        }
        site_publication_date
        ogp_images {
          title
          description
          file {
            fileName
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
        logo_image {
          file {
            url
          }
        }
      }
      pablish_sort_data: allContentfulBlogPost(
        sort: { fields: published_date, order: DESC }
        limit: 1
      ) {
        edges {
          node {
            published_date
          }
        }
      }
      modified_sort_data: allContentfulBlogPost(
        filter: { modified_date: { ne: null } }
        sort: { fields: modified_date, order: DESC }
        limit: 1
      ) {
        edges {
          node {
            modified_date
          }
        }
      }
      allContentfulBlogPost(
        sort: { fields: published_date, order: DESC }
        limit: 3
      ) {
        edges {
          node {
            title
            author {
              author_name
              url
            }
            ogp_images {
              file {
                fileName
                url
              }
            }
            category {
              name
              ogp_images {
                file {
                  fileName
                  url
                }
              }
            }
          }
        }
      }
    }
  `)
  const meta_data = query.contentfulSiteMetaData
  const posts = query.allContentfulBlogPost.edges.map(({ node }) => {
    return {
      headline: `【${node.category.name}】${node.title}`,
      image:
        (node.ogp_images && node.ogp_images[0].file.url) ??
        (node.category.ogp_images[0] &&
          node.category.ogp_images[0].file &&
          node.category.ogp_images[0].file.url) ??
        meta_data.ogp_images[0].file.url,
      author: node.author ?? meta_data.author_data,
    }
  })
  const last_update_date = compare_dates_return_newer(
    query.pablish_sort_data.edges[0].node.published_date,
    query.modified_sort_data.edges[0].node.modified_date
  )
  const common_variavle = {
    page_type: 'website' as const,
    // ポスト報関連
    site_title: meta_data.site_title,
    page_title: meta_data.site_title,
    page_description: meta_data.site_description,
    ogp_images: meta_data.ogp_images,
    logo_image: meta_data.logo_image.file.url,
    author: meta_data.author_data,
    page_fullpath: meta_data.site_url,
    published_date: meta_data.site_publication_date,
    modified_date: last_update_date,
    posts_list: posts,
  }
  return (
    <>
      <PostMetadata metadata={common_variavle} />
      <JsonLdMetadata metadata={common_variavle} />
    </>
  )
}

export default IndexMetadata
