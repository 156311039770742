import React from 'react'

import { PagesIndexQuery } from '../../../../types/graphql-types'

import FewNewBlogPosts from './FewNewBlogPosts'
import ManyNewBlogPosts from './ManyNewBlogPosts'

const number_of_changing_articles = 9

type props_type = {
  master_category_edges: PagesIndexQuery['allContentfulMasterCategory']['edges']
  all_blog_post_number: number
}

const IndexNewBlogPostList: React.FC<props_type> = ({
  master_category_edges,
  all_blog_post_number,
}) => {
  return (
    <>
      {all_blog_post_number > number_of_changing_articles ? (
        <ManyNewBlogPosts master_category_edges={master_category_edges} />
      ) : (
        <FewNewBlogPosts />
      )}
    </>
  )
}

export default IndexNewBlogPostList
